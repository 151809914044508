import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IKeycloakProfileLinks } from '@shared/keycloak/keycloak-profile.interface';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { from, Observable } from 'rxjs';

@Injectable()
export class KeycloakWrapperService {
  constructor(private keycloak: KeycloakService) {}

  getUserProfile(): Observable<KeycloakProfile> {
    return from(this.keycloak.loadUserProfile());
  }

  getProfileLinks(): IKeycloakProfileLinks {
    return environment.profile;
  }

  logout() {
    const url = environment.keycloak.url;
    const realm = environment.keycloak.realm;
    const clientId = environment.keycloak.clientId;
    const redirectUrl = `${url}/auth/realms/${realm}/logged-out?client_id=${clientId}` as any;
    this.keycloak.logout(redirectUrl);
  }
}
