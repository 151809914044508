import urlJoin from 'url-join';

export let getEnv = (key: string) => {
  return (<any>window).$ENV && (<any>window).$ENV[key];
};

export const defaultEnv = {
  isBaseEnviroment: true,
  graphql: 'https://api-nedasco-qa.dev.onsweb.com/graphql',
  keycloak: {
    url: 'https://sso-nedasco-qa.dev.onsweb.com/',
    realm: 'nedasco',
    clientId: 'portal',
  },
  configServiceAPI: 'https://api-product-config-qa.acc.onsweb.com/api/v1',
  googleMapsApiKey: 'AIzaSyAmiw2gvWiOzziXxP8Pj-M6lM2kI_63-i8',
  profile: {
    manageProfileUrl:
      'https://sso-nedasco-qa.dev.onsweb.com/auth/realms/nedasco/account',
  },
};

export function buildEnv(env) {
  const internalEnv = { ...defaultEnv, ...env };
  const KEYCLOAK = {
    url: getEnv('FE_KEYCLOAK_URL') || internalEnv.keycloak.url,
    realm: getEnv('FE_KEYCLOAK_REALM') || internalEnv.keycloak.realm,
    clientId: getEnv('FE_KEYCLOAK_CLIENTID') || internalEnv.keycloak.clientId,
  };
  return {
    isBaseEnviroment: internalEnv.isBaseEnviroment,
    production: false,
    lang: 'nl',
    graphql: getEnv('FE_GRAPHQL_URL') || internalEnv.graphql,
    googleMapsApiKey:
      getEnv('FE_GOOGLE_MAPS_API_KEY') || internalEnv.googleMapsApiKey,
    keycloak: {
      ...KEYCLOAK,
    },
    profile: {
      get changePasswordUrl(): string {
        const FE_CHANGE_PASSWORD_URL =
          getEnv('FE_CHANGE_PASSWORD_URL') || internalEnv.profile
            ? internalEnv.profile.changePasswordUrl
            : '';
        if (FE_CHANGE_PASSWORD_URL) {
          return FE_CHANGE_PASSWORD_URL;
        }
        const { url, realm } = KEYCLOAK;
        return urlJoin([url, '/auth/realms/', realm, '/account/password']);
      },
      get manageProfileUrl(): string {
        const FE_MANAGE_PROFILE_URL =
          getEnv('FE_MANAGE_PROFILE_URL') || internalEnv.profile
            ? internalEnv.profile.manageProfileUrl
            : '';
        if (FE_MANAGE_PROFILE_URL) {
          return FE_MANAGE_PROFILE_URL;
        }

        const { url, realm } = KEYCLOAK;
        return urlJoin([url, '/auth/realms/', realm, '/account']);
      },
    },
    // TODO: tell Marien about FE_CONFIG_SERVICE_API
    configServiceAPI:
      getEnv('FE_CONFIG_SERVICE_API') || internalEnv.configServiceAPI,
  };
}
